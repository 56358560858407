import { getEnvValue } from '@palette/helpers/ConfigHelper';

const app = {
  SHOW_MARKETING_BANNER: process.env.SHOW_MARKETING_BANNER === 'true' || process.env.SHOW_MARKETING_BANNER === true || false,
  ALGOLIA_APP_ID: getEnvValue(process.env.ALGOLIA_APP_ID),
  ALGOLIA_API_KEY: getEnvValue(process.env.ALGOLIA_API_KEY),
  ALGOLIA_DOC_INDEX_NAME: getEnvValue(process.env.ALGOLIA_DOC_INDEX_NAME),
  ASYNC_JOB_POLLING_SLEEP_DURATION: parseInt(getEnvValue(process.env.ASYNC_JOB_POLLING_SLEEP_DURATION, '1000'), 10),
  ASYNC_JOB_PROGRESS_DELAY: parseInt(getEnvValue(process.env.ASYNC_JOB_PROGRESS_DELAY, '120'), 10),
  CLOUDINARY_API: getEnvValue(process.env.CLOUDINARY_API),
  CLOUDINARY_NAME: getEnvValue(process.env.CLOUDINARY_NAME),
  CLOUDINARY_PRESET: getEnvValue(process.env.CLOUDINARY_PRESET),
  GOOGLE_CLIENT_ID: getEnvValue(process.env.GOOGLE_CLIENT_ID),
  PASSWORD_MIN_LENGTH: parseInt(getEnvValue(process.env.PASSWORD_MIN_LENGTH, 8), 10),
  PASSWORD_UPPERCASE_LETTERS_QUANTITY: parseInt(getEnvValue(process.env.PASSWORD_UPPERCASE_LETTERS_QUANTITY, 1), 10),
  PASSWORD_LOWERCASE_LETTERS_QUANTITY: parseInt(getEnvValue(process.env.PASSWORD_LOWERCASE_LETTERS_QUANTITY, 1), 10),
  PASSWORD_NUMBERS_QUANTITY: parseInt(getEnvValue(process.env.PASSWORD_NUMBERS_QUANTITY, 1), 10),
  PASSWORD_SYMBOLS_QUANTITY: parseInt(getEnvValue(process.env.PASSWORD_SYMBOLS_QUANTITY, 1), 10),
  REACT_APP_PUBLIC_POSTHOG_KEY: getEnvValue(process.env.REACT_APP_PUBLIC_POSTHOG_KEY),
  REACT_APP_PUBLIC_POSTHOG_HOST: getEnvValue(process.env.REACT_APP_PUBLIC_POSTHOG_HOST),
  COMPANY_DUMP_DATA_RETENTION_DAYS: parseInt(getEnvValue(process.env.COMPANY_DUMP_DATA_RETENTION_DAYS, 7), 10),
};

export default app;
