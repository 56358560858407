const api = {
  BASE_URL: process.env.DEV_URL || 'http://localhost:5854',
  TIMEOUT: process.env.API_TIMEOUT || 10000,

  PROBLEM_BASE_URI: process.env.PROBLEM_BASE_URI || 'palette:error:',

  ENDPOINTS: {
    analytics: {
      logEvent: '/users/log-event',
    },
    asyncJobs: {
      compute: '/users/compute',
      getById: '/users/get-async-job-by-id',
      getICPeriodEstimation: '/users/ic/estimate-commissions',
      getPeriodEstimationForUser: '/users/admin/plans/estimate-commissions',
    },
    auditTrail: {
      list: '/users/admin/audit-trails/list',
    },
    commissionV3: {
      detailsCommission: '/users/admin/plan-v3/commissions/{commissionId}',
      overwriteCommission: '/users/admin/plan-v3/commissions/{commissionId}/overwrites/amount',
      overwritePayments: '/users/admin/plan-v3/commissions/{commissionId}/overwrites/payments',
    },
    commissions: {
      getById: '/users/admin/commissions/get-by-id',
      getByIdFromIC: '/users/ic/get-commission-by-id',
      getByIdFromSF: '/sf/get-commission-by-id',
      overwriteAmount: '/users/admin/commissions/overwrite-amount',
      overwriteValue: '/users/admin/commissions/overwrite-value',
      updatePayoutSchedule: '/users/admin/commissions/update-payments',
      listDealCommissions: '/users/admin/commissions/v2/list-deal-commissions',
      commissionListByFilters: '/users/admin/commissions/v2/list-commissions',
      commissionListByFiltersDashboard: '/users/admin/commissions/v2/list-commissions-dashboard',
    },
    connectors: {
      listResourceObjects: '/users/admin/objects/list',
      resetLastSyncDates: '/users/admin/connectors/set-resources-last-sync-dates',
      getList: '/users/admin/connectors/list',
      getResourceFields: '/users/admin/connectors/get-resource-fields',
      deleteResource: '/users/admin/connectors/delete-resource',
      getResourceConfigurationsByConnectorId: '/users/admin/connector-v2/resource-configurations/list',
      getAvailabeConfigurationsFields: '/users/admin/connector-v2/list-resource-fields',
      upsertResourceConfigurations: '/users/admin/connector-v2/resource-configurations/upsert',
    },
    company: {
      updateCompany: '/users/admin/company/update',
      listUsersInPlans: '/users/admin/company/list-users-in-plans',
      updateStatementStrategy: '/users/admin/company/update-statement-period-strategy',
      toggleStatementValidations: '/users/admin/company/toggle-statement-validation',
      getSeatsCount: '/users/admin/company/seats/get',
      getAggregatedFxRates: '/users/admin/company/fx-rates/aggregate',
      listFxRates: '/users/admin/company/fx-rates/list',
      createFxRate: '/users/admin/company/fx-rates/create',
      deleteFxRate: '/users/admin/company/fx-rates/delete',
      getSubscription: '/users/admin/company/billing/get-subscription',
      getCustomerPortalUrl: '/users/admin/company/billing/create-customer-portal-session',
      updateSeats: '/users/admin/company/billing/update-seats',
      updateStatementFxRateMode: '/users/admin/company/update-statement-fx-rate-mode',
      generateDump: '/users/admin/company/dump-data',
      downloadDump: '/users/admin/company/dump-file',
    },
    dashboard: {
      updateComponents: '/users/common/dashboard/update',
      listComponents: '/users/ic/v2/dashboard/list',
    },
    dashboardPresets: {
      getList: '/users/admin/company/dashboard-presets/list',
      getById: '/users/admin/company/dashboard-presets/get-by-id',
      createDashboardPreset: '/users/admin/company/dashboard-presets/create',
      updateDashboardPreset: '/users/admin/company/dashboard-presets/update',
      getPresetCandidateUsers: '/users/admin/company/dashboard-presets/list-users',
      previewDashboardForUser: '/users/admin/users/v2/preview-dashboard',
      deleteById: '/users/admin/company/dashboard-presets/delete',
      applyToUsers: '/users/admin/users/bulk-update',
    },
    folders: {
      list: '/users/admin/folders/list',
      create: '/users/admin/folders/create',
      update: '/users/admin/folders/update',
      delete: '/users/admin/folders/delete',
      moveEntities: '/users/admin/folders/move-entities',
    },
    ic: {
      commissions: {
        commissionListByFilters: '/users/ic/v2/commissions/list-commissions',
        commissionListByFiltersDashboard: '/users/ic/v2/commissions/list-commissions-dashboard',
      },
      commissionV3: {
        detailsCommission: '/users/ic/plan-v3/commissions/{commissionId}',
      },
      connectors: {
        list: '/users/ic/connectors/list',
      },
      masterPlans: {
        list: '/users/ic/v2/plans/list',
        listLastPeriods: '/users/ic/v2/plans/list-last-periods',
        getById: '/users/ic/v2/plans/get-by-id',
        getPeriods: '/users/ic/v2/plans/list-periods',
        getPlanDashboard: '/users/ic/v2/plans/get-plan-dashboard',
        getPeriodBy: '/users/ic/v2/plans/get-period-by-id',
        getPeriodDeals: '/users/ic/v2/plans/deals/list',
        getPeriodDashboard: '/users/ic/v2/plans/get-period-dashboard',
      },
      statements: {
        listMyStatementPeriods: '/users/ic/statements/list-periods',
        getICUserStatement: '/users/ic/statements/get-statement',
        downloadMyUserStatements: '/users/ic/statements/exports/by-statement',
      },
      planV3: {
        crud: '/users/ic/plan-v3',
        configurationCrud: '/users/ic/plan-v3/{planId}/configuration',
      },
      statementV3: {
        listPeriods: '/users/ic/plan-v3/statements/periods?from={from}&to={to}',
        oneStatement: '/users/ic/plan-v3/statements/{statementId}',
        listCorrections: '/users/ic/plan-v3/statements/{statementId}/corrections',
        listCommissions: '/users/ic/plan-v3/commissions/payments?statementId={statementId}',
      },
    },
    masterPlans: {
      list: '/users/admin/plans/v2/list',
      listLastPeriods: '/users/admin/plans/v2/list-last-periods',
      getById: '/users/admin/plans/v2/get-by-id',
      archiveById: '/users/admin/plans/v2/archive',
      deleteById: '/users/admin/plans/v2/delete',
      getPeriods: '/users/admin/plans/v2/list-periods',
      getPeriodBy: '/users/admin/plans/v2/get-period-by-id',
      getPlanUsers: '/users/admin/plans/v2/users/list',
      getPlanDashboard: '/users/admin/plans/v2/get-plan-dashboard',
      getPeriodDeals: '/users/admin/plans/v2/deals/list',
      getPeriodDashboard: '/users/admin/plans/v2/get-period-dashboard',
      createPlan: '/users/admin/plans/v2/create',
      updatePlan: '/users/admin/plans/v2/update',
      updatePlanRules: '/users/admin/plans/v2/update-rules',
      updatePayoutRules: '/users/admin/plans/v2/update-payout-rules',
      addUsersToPlan: '/users/admin/plans/v2/users/add',
      removeUsersFromPlan: '/users/admin/plans/v2/users/delete',
      updatePlanUserDates: '/users/admin/plans/v2/users/set-dates',
      updatePlanUsersDefinition: '/users/admin/plans/v2/update-user-definition',
      updatePlanTrackingObjectDefinition: '/users/admin/plans/v2/update-deal-definition',
      getPlanDealObjectSample: '/users/admin/plans/v2/deals/sample',
      duplicatePlan: '/users/admin/plans/v2/duplicate',
      listPlansForUser: '/users/admin/plans/v2/list-for-user',
      getPayoutSchedule: '/users/admin/plans/v2/get-payout-schedule',
    },
    planV3: {
      crud: '/users/admin/plan-v3',
      folderUpdate: '/users/admin/plan-v3/{planId}/folder',
      listUsers: '/users/admin/plan-v3/selectable-users',
      configurationCrud: '/users/admin/plan-v3/{planId}/configuration',
      details: '/users/admin/plan-v3/{planId}',
      runCodeCell: '/users/admin/plan-v3/{planId}/code-cells/{codeCellId}/run',
      savePlan: '/users/admin/plan-v3/{planId}/save',
      achievementsList: '/users/admin/plan-v3/{planId}/achievements',
      inputTablesList: '/users/admin/plan-v3/{planId}/input-tables',
      createInputTable: '/users/admin/plan-v3/{planId}/input-tables',
      addRowToInputTable: '/users/admin/plan-v3/{planId}/input-tables/{inputTableId}/rows',
      deleteRowFromInputTable: '/users/admin/plan-v3/{planId}/input-tables/{inputTableId}/rows/{rowPosition}',
      addColumnToInputTable: '/users/admin/plan-v3/{planId}/input-tables/{inputTableId}/columns',
      deleteColumnFromInputTable: '/users/admin/plan-v3/{planId}/input-tables/{inputTableId}/columns/{columnId}',
      updateCellContentFromInputTable: '/users/admin/plan-v3/{planId}/input-tables/{inputTableId}/rows/{rowPosition}',
      updateColumnFromInputTable: '/users/admin/plan-v3/{planId}/input-tables/{inputTableId}/columns/{columnId}',
      freezePeriod: '/users/admin/plan-v3/{planId}/periods/{periodId}/freeze',
      unfreezePeriod: '/users/admin/plan-v3/{planId}/periods/{periodId}/unfreeze',
      generateWithAI: '/users/admin/plan-v3/{planId}/code-cells/{codeCellId}/generate',
    },
    statementV3: {
      listPeriods: '/users/admin/plan-v3/statements/periods?from={from}&to={to}&currency={currency}',
      detailsPeriod: '/users/admin/plan-v3/statements?periodId={period}&currency={currency}',
      oneStatement: '/users/admin/plan-v3/statements/{statementId}?currency={currency}',
      addAction: '/users/admin/plan-v3/statements/{statementId}/actions',
      addMultipleActions: '/users/admin/plan-v3/statements/actions/bulk',
      deleteAction: '/users/admin/plan-v3/statements/{statementId}/actions/delete',
      listCorrections: '/users/admin/plan-v3/statements/{statementId}/corrections?currency={currency}',
      correctionSuggestedValidation: '/users/admin/plan-v3/statements/{statementId}/corrections/{correctionId}/validation',
      createCorrection: '/users/admin/plan-v3/statements/{statementId}/corrections',
      updateCorrection: '/users/admin/plan-v3/statements/{statementId}/corrections/{correctionId}',
      deleteCorrection: '/users/admin/plan-v3/statements/{statementId}/corrections/{correctionId}',
      listCommissions: '/users/admin/plan-v3/commissions/payments?statementId={statementId}&currency={currency}',
    },
    notebooks: {
      list: '/users/admin/notebooks/list',
      create: '/users/admin/notebooks/create',
      edit: '/users/admin/notebooks/edit',
      duplicate: '/users/admin/notebooks/duplicate',
      updateStatus: '/users/admin/notebooks/update-status',
      delete: '/users/admin/notebooks/delete',
      getById: '/users/admin/notebooks/get-one',
      upsertCell: '/users/admin/notebooks/code-cells/upsert',
      deleteCell: '/users/admin/notebooks/code-cells/delete',
      runCell: '/users/admin/notebooks/code-cells/run',
      upsertDataConnectionConnector: '/users/admin/notebooks/connector-definitions/upsert',
      previewDataConnectionConnector: '/users/admin/notebooks/connector-definitions/preview',
    },
    profile: {
      getProfile: '/users/get-profile',
      updateProfile: '/users/update-profile',
      forgotPassword: '/users/forget-password',
      login: '/users/login',
      resetPassword: '/users/reset-password',
      acknowledgeTermsAndConditions: '/users/acknowledge-terms',
      logout: '/users/logout',
      sendFeedback: '/users/send-product-feedback',
    },
    quotas: {
      list: '/users/admin/quotas/list',
      getById: '/users/admin/quotas/get-by-id',
      create: '/users/admin/quotas/create',
      addUsersToQuota: '/users/admin/quotas/users/bulk-add',
      updateQuota: '/users/admin/quotas/update-by-id',
      updateUserFromQuota: '/users/admin/quotas/users/update',
      listQuotasByUser: '/users/admin/quotas/list-by-user',
      deleteById: '/users/admin/quotas/delete',
      removeUserFromQuota: '/users/admin/quotas/users/delete',
      exportAllByIdAndYear: '/users/admin/quotas/export',
    },
    quotasPerformance: {
      listDashboard: '/users/admin/global-achievement-dashboards/list',
      getDashboardById: '/users/admin/global-achievement-dashboards/get-by-id',
      createDashboard: '/users/admin/global-achievement-dashboards/create',
      deleteDashboard: '/users/admin/global-achievement-dashboards/delete',
      updateDashboard: '/users/admin/global-achievement-dashboards/edit',
      addPlansToDashboard: '/users/admin/global-achievement-dashboards/add-plans',
      removePlanFromDashboard: '/users/admin/global-achievement-dashboards/remove-plan',
    },
    resources: {
      getById: '/users/admin/objects/get-by-id',
      overwrite: '/users/admin/objects/overwrites/create',
      deleteOverwrite: '/users/admin/objects/overwrites/bulk-delete',
      clone: '/users/admin/objects/clone',
      delete: '/users/admin/objects/delete',
    },
    roles: {
      list: '/users/admin/roles/list',
      delete: '/users/admin/roles/delete',
      duplicate: '/users/admin/roles/duplicate',
      update: '/users/admin/roles/update',
      getRoleById: '/users/admin/roles/get-by-id',
      listRights: '/users/list-rights',
      create: '/users/admin/roles/create',
    },
    sf: {
      statements: {
        listSFStatementPeriods: '/sf/statements/list-periods',
        getSFUserStatement: '/sf/statements/get-statement',
      },
    },
    statements: {
      listStatementsValidations: '/users/common/statements/validations/list',
      listStatementsSnapshots: '/users/admin/statements/snapshots/list',
      statementValidationDownload: '/users/common/statements/validations/download-snapshot',
      downloadSnapshot: '/users/admin/statements/snapshots/download-snapshot',
      getStatementPeriodById: '/users/admin/statements/get-period',
      listStatementPeriods: '/users/admin/statements/list-periods',
      getUserStatementById: '/users/admin/statements/get-statement',
      listHistoryChangesById: '/users/admin/statements/history/list-events',
      addAdjustment: '/users/admin/statements/adjustments/create',
      updateAdjustment: '/users/admin/statements/adjustments/update',
      deleteAdjustment: '/users/admin/statements/adjustments/delete',
      approveStatements: '/users/admin/statements/approvals/bulk-approve',
      cancelStatementApproval: '/users/admin/statements/approvals/remove-approval',
      markAsPaidStatements: '/users/admin/statements/bulk-mark-as-paid',
      applyCorrection: '/users/admin/statements/corrections/apply',
      ignoreCorrection: '/users/admin/statements/corrections/ignore',
      investigateCorrectionSummaries: '/users/admin/statements/changes/commissions-summary',
      investigateCorrectionDetails: '/users/admin/statements/changes/by-statement',
      downloadPeriodUsersStatements: '/users/admin/statements/exports/by-period',
      downloadUserStatements: '/users/admin/statements/exports/by-statement',
      exportToPayroll: '/users/admin/statements/exports/payroll-by-period',
      markAsDirty: '/users/admin/statements/mark-as-dirty',
    },
    users: {
      inviteUser: '/users/admin/users/invite',
      resendUserInvitation: '/users/admin/users/bulk-resend-invite',
      list: '/users/admin/users/list',
      listCurrencies: '/users/list-currencies',
      getUserById: '/users/admin/users/get-by-id',
      getUserResourcesCommonData: '/users/admin/users/get-users-common-data',
      onboardUsers: '/users/admin/users/onboard-users',
      updateUser: '/users/admin/users/update',
      deactivateUser: '/users/admin/users/deactivate',
      unlinkResourceFromUser: '/users/admin/users/unlink-cobject-user',
      linkResourceToUser: '/users/admin/users/link-cobject-user',
      offboardUser: '/users/admin/users/offboard',
      getMergeConflicts: '/users/admin/users/get-merge-conflicts',
    },
    teams: {
      list: '/users/admin/teams/list',
      getById: '/users/admin/teams/get-by-id',
      updateMemberType: '/users/admin/teams/members/update-type',
      removeMemberFromTeam: '/users/admin/teams/members/delete',
      addMembersToTeam: '/users/admin/teams/members/bulk-add',
      createTeam: '/users/admin/teams/create',
      deleteTeam: '/users/admin/teams/delete',
      updateTeamName: '/users/admin/teams/update',
      moveTeam: '/users/admin/teams/update',
      getTeamsForUser: '/users/admin/users/list-teams',
    },
    threads: {
      list: '/users/common/threads/list',
      getOne: '/users/common/threads/get-one',
      create: '/users/common/threads/create',
      addComment: '/users/common/threads/add-comment',
      setStatus: '/users/common/threads/update-status',
    },
    usersVariables: {
      getVariables: '/users/admin/users/variables/list',
      createVariable: '/users/admin/users/variables/create',
      editVariable: '/users/admin/users/variables/bulk-edit',
      deleteVariableValueOverride: '/users/admin/users/variables/delete-override',
    },
  },
};

export default api;
