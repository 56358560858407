import {
  call,
  put,
  all,
  takeLatest,
} from 'redux-saga/effects';

import { actions as CompanyActions } from '@palette/state/Company/slice';
import { manageError as manageCompanyError } from '@palette/state/Company/errors';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import * as CompanyService from '@palette/services/CompanyService';

import * as RoleModel from '@palette/models/Role';
import apiConfig from '@palette/config/api';
import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

export function* getRoles({ payload }) {
  const { withUsersCount = false } = payload;

  const callResult = yield call(CompanyService.getRoles, { withUsersCount });

  if (callResult.ok) {
    const roles = RoleModel.transformList(callResult.data);
    yield put(CompanyActions.setRoles({ roles }));
  } else {
    const error = manageCompanyError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CompanyActions.getRolesCompleted());
}

export function* getRights() {
  const callResult = yield call(CompanyService.getRights);

  if (callResult.ok) {
    yield put(CompanyActions.setRights({ rights: callResult.data }));
  } else {
    const error = manageCompanyError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CompanyActions.getRightsCompleted());
}

export function* deleteRoleById({ payload }) {
  const { roleId, onSuccessCB = null } = payload;

  const callResult = yield call(CompanyService.deleteRoleById, { roleId });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageCompanyError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CompanyActions.deleteRoleByIdCompleted());
}

export function* duplicateRoleById({ payload }) {
  const { roleId, onSuccessCB = null } = payload;

  const callResult = yield call(CompanyService.duplicateRoleById, { roleId });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageCompanyError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CompanyActions.duplicateRoleByIdCompleted());
}

export function* makeDefaultRoleById({ payload }) {
  const { roleId, onSuccessCB = null } = payload;

  const callResult = yield call(CompanyService.makeDefaultRoleById, { roleId });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageCompanyError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CompanyActions.makeDefaultRoleByIdCompleted());
}

export function* getRoleById({ payload }) {
  const { roleId, onSuccessCB = null } = payload;

  const callResult = yield call(CompanyService.getRoleById, { roleId });

  if (callResult.ok) {
    const role = RoleModel.transform(callResult.data);
    yield put(CompanyActions.setRole({ role }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageCompanyError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CompanyActions.getRoleByIdCompleted());
}

export function* updateRole({ payload }) {
  const {
    roleId,
    name = null,
    description = null,
    rights = null,
    onSuccessCB = null,
  } = payload;

  const callData = {
    roleId,
    name,
    description,
    rights,
  };

  const callResult = yield call(CompanyService.updateRole, callData);

  if (callResult.ok) {
    const role = RoleModel.transform(callResult.data);
    yield put(CompanyActions.setRole({ role }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageCompanyError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CompanyActions.updateRoleCompleted());
}

export function* createRole({ payload }) {
  const {
    roleId,
    name,
    description = '',
    rights = [],
    onSuccessCB = null,
  } = payload;

  const callData = {
    roleId,
    name,
    description,
    rights,
  };

  const callResult = yield call(CompanyService.createRole, callData);

  if (callResult.ok) {
    const role = RoleModel.transform(callResult.data);
    yield put(CompanyActions.setRole({ role }));

    if (onSuccessCB !== null) onSuccessCB(role.id);
  } else {
    const error = manageCompanyError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CompanyActions.createRoleCompleted());
}

export function* generateDump() {
  const callResult = yield call(CompanyService.generateDump);

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.COMPANY_GENERATE_DUMP_SUCCESS));
  } else {
    const error = manageCompanyError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CompanyActions.generateDumpCompleted());
}

export function* downloadDump() {
  const callResult = yield call(CompanyService.downloadDump);

  if (callResult.ok) {
    window.open(`${apiConfig.BASE_URL}/downloads/${callResult.data._id}`, '_blank');
  } else {
    const error = manageCompanyError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CompanyActions.downloadDumpCompleted());
}

export function* loop() {
  yield all([
    takeLatest(CompanyActions.getRoles.type, getRoles),
    takeLatest(CompanyActions.getRights.type, getRights),
    takeLatest(CompanyActions.deleteRoleById.type, deleteRoleById),
    takeLatest(CompanyActions.duplicateRoleById.type, duplicateRoleById),
    takeLatest(CompanyActions.makeDefaultRoleById.type, makeDefaultRoleById),
    takeLatest(CompanyActions.getRoleById.type, getRoleById),
    takeLatest(CompanyActions.updateRole.type, updateRole),
    takeLatest(CompanyActions.createRole.type, createRole),
    takeLatest(CompanyActions.generateDump.type, generateDump),
    takeLatest(CompanyActions.downloadDump.type, downloadDump),
  ]);
}

export function* clean() {
  yield put(CompanyActions.resetToInitialState());
}
